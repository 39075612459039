<template>
  <div>
    <div class="facilityBox">
      <div style="position: absolute; right: 20px; top: 125px; z-index: 999">
        <el-tooltip placement="top">
          <div slot="content">
            1.请平台客服仔细核查货主的推荐人身份，TAB页可区分推荐人来源<br />2.所有企业都可以是买家，与买家/推荐人沟通确认后，对买家设置费率<br />3.必须设置平台所有场景类型，不设置，则采取名义税率作为默认值<br />
          </div>
          <div style="margin: 0px 20px; color: #0f5fff">
            <i class="el-icon-question"></i>
            <span>帮助</span>
          </div>
        </el-tooltip>
      </div>
      <!-- form表单 -->
      <el-form class="manageForm" :model="manageForm" :inline="true">
        <el-form-item label="企业名称" prop="EnterpriseFullName" label-width="90px">
          <el-input class="fromInp" v-model="manageForm.EnterpriseFullName" placeholder="输入企业名称"
            @keyup.enter.native="search()"></el-input>
        </el-form-item>
        <el-form-item label="推荐人ID" prop="LegalPersonlPhone" label-width="90px">
          <el-input class="fromInp" maxlength="11" v-model="manageForm.LegalPersonlPhone" placeholder="输入推荐人ID"
            @keyup.enter.native="search()"></el-input>
        </el-form-item>
        <el-form-item label="签订日期" prop="TaskDatetime" label-width="90px">
          <el-date-picker v-model="manageForm.TaskDatetime" type="daterange" range-separator="至" start-placeholder="开始日期"
            value-format="yyyy-MM-dd" end-placeholder="结束日期" @change="searchChange()">
          </el-date-picker>
        </el-form-item>
        <el-form-item label-width="10px">
          <el-button type="primary" size="medium" @click="search()" icon="el-icon-search">搜索</el-button>
          <el-button type="primary" size="medium" @click="resetForm()" icon="el-icon-delete">清空</el-button>
        </el-form-item>
      </el-form>
      <el-tabs v-model="activeCmpt" @tab-click="cmptChange">
        <el-tab-pane label="代理商(推荐企业)" name="1"></el-tab-pane>
        <el-tab-pane label="服务商(推荐企业)" name="2"></el-tab-pane>
        <el-tab-pane label="平台(推荐企业)" name="0"></el-tab-pane>
      </el-tabs>
      <el-table :data="tableData" :header-cell-style="{ color: '#666', background: '#f0f0f0' }" v-loading="loading">
        <el-table-column type="index" align="center" label="序号" fixed width="50" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="EnterpriseFullName" fixed label="企业名称" width="340" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="EnterpriseCreditCode" label="企业统一社会信用代码" width="180"
          show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="EnterpriseAddress" label="企业地址" width="440" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="CollectTime" label="签订日期" width="180" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="AgentName" label="推荐人名称" width="120" show-overflow-tooltip
          v-if="activeCmpt != 2">
        </el-table-column>
        <el-table-column align="center" prop="AgentFullName" label="推荐人企业" width="120" show-overflow-tooltip v-else>
        </el-table-column>
        <el-table-column align="center" prop="AgentPhone" label="推荐人ID" width="120" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" label="操作" width="350" fixed="right">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="setDivide(scope.row)" icon="el-icon-setting">设置</el-button>
            <el-button type="primary" size="small" @click="goFacility(scope.row)" icon="el-icon-tickets">推荐人ID
            </el-button>
            <el-button type="primary" size="small" @click="examineRate(scope.row)" icon="el-icon-search">费率
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 10px">
        <!-- 分页 -->
        <el-pagination background class="pagination" @current-change="handleCurrentChange"
          :current-page.sync="pagination.page" :page-size="pagination.pagesize" layout="total, prev, pager, next, jumper"
          :total="pagination.total">
        </el-pagination>
      </div>
    </div>
    <!-- 设置分成比例弹窗 -->
    <el-dialog :visible.sync="flag.setDivide" width="70%" class="deep_dialog deep_dialog1" :lock-scroll="false"
      append-to-body>
      <span slot="title">设置分成比例</span>
      <div class="dialog_box" style="position: relative">
        <el-popover placement="left-start" width="250" trigger="hover">
          <span>温馨提示：</span><br />
          1，请确认发票对应费率，确认后，即按双方约定结算；<br />
          2，买家结算费率若不填写，默认买家只有技术服务费（一票制）；<br />
          3，技术服务费若不填写，默认买家将运费、税费、技术服务费开具一张发票（一票制）；<br />
          4，买家结算费率和技术服务费都填写的情况下，将运费和税费单独开具一张发票；技术服务费单独开具一张发票（两票制）。<br />
          <i class="el-icon-question" style="
              position: absolute;
              right: 10px;
              top: 5px;
              color: red;
              font-size: 14px;
            " slot="reference"></i>
        </el-popover>
        <div class="dialogRole">
          <strong>当前角色：</strong><u>买家</u><br />
        </div>
        <div class="dialogInfo">
          <div>
            <strong>注册ID：</strong><u>{{ ownerInfo.LegalPersonlPhone }} </u><br />
            <strong>企业名称：</strong><u> {{ ownerInfo.EnterpriseFullName }}</u><br />
            <strong>身份证号码：</strong><u> {{ ownerInfo.LegalPersonIIDCard }}</u><br />
          </div>
          <div>
            <strong>姓名： </strong><u>{{ ownerInfo.LegalPersonIName }} </u><br />
            <strong>电话：</strong><u>{{ ownerInfo.LegalPersonlPhone }} </u><br />
            <strong>信用代码：</strong><u> {{ ownerInfo.EnterpriseCreditCode }}</u><br />
          </div>
        </div>
        <el-table :data="invoiceRate" :header-cell-style="{ color: '#666', background: '#f0f0f0' }" v-loading="loading"
          :max-height="500">
          <el-table-column align="center" label="发票-业务-场景">
            <template slot-scope="scope">
              <span v-if="scope.row.TaskSceneName">
                {{ scope.row.InvoiceTypeName }} / {{ scope.row.TaskTypeName }} /
                {{ scope.row.TaskSceneName }}</span>
              <span v-else> {{ scope.row.InvoiceTypeName }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="费率">
            <template slot-scope="scope">
              <el-input class="fromInp" v-model="scope.row.Rate" placeholder="输入费率（固定扣点）" maxlength="5"
                onkeyup="value=value.replace(/[^0-9]+(\.?)+([0-9]{3})$|[^0-9]+(\..?)$|00/,'')"
                oninput="if(value > 99 || value < 0 ){value = ''}" @input="raetInp(scope.row, $event)">
                <template slot="append">%</template>
              </el-input>
            </template>
          </el-table-column>
          <el-table-column align="center" label="技术服务费率">
            <template slot-scope="scope">
              <el-input class="fromInp" v-model="scope.row.TecRate" placeholder="技术服务费率" maxlength="5"
                onkeyup="value=value.replace(/[^0-9]+(\.?)+([0-9]{3})$|[^0-9]+(\..?)$|00/,'')"
                oninput="if(value > 99 || value < 0 ){value = ''}" @input="raetInp(scope.row, $event)">
                <template slot="append">%</template>
              </el-input>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="flag.setDivide = false">取 消</el-button>
        <el-button type="primary" @click="setOwnerDivide()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 设置推荐人弹窗 -->
    <el-dialog :visible.sync="flag.setReferrer" width="500px" class="deep_dialog">
      <span slot="title">设置推荐人</span>
      <div class="dialog_box" style="
          width: 100%;
          text-align: center;
          margin-top: 10px;
          padding: 20px 5% 0 5%;
        ">
        <el-form class="manageForm" :inline="true">
          <el-form-item label="推荐人ID" prop="AgentPhone" label-width="90px">
            <el-input class="fromInp" v-model="AgentPhone" placeholder="输入推荐人ID" maxlength="11"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <el-alert style="margin-top: 10px" type="warning" :closable="false">
        <span slot="title">
          <span>温馨提示：</span><br />
          1，在输入框中，为这位货主配置推荐人ID；<br />
          2，不论其是否曾绑定过推荐人，都将在您配置的新推荐人名下。<br />
        </span>
      </el-alert>
      <span slot="footer" class="dialog-footer">
        <el-button @click="flag.setReferrer = false">取 消</el-button>
        <el-button type="primary" @click="setReferrer()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 查看费率 -->
    <el-dialog :visible.sync="flag.rateMdel" width="70%">
      <span slot="title">查看费率</span>
      <div class="dialog_box">
        <div class="dialogRole">
          <strong>买家名称：</strong><u>{{ ownerInfo.EnterpriseFullName }}</u> <span>（{{ fadadaStatus == 100 ? '已认证' : '未认证'
          }}）</span>
          <br />
        </div>
        <el-table :data="rateData" :header-cell-style="{ background: '#f0f0f0', color: '#666' }" v-loading="loading"
          max-height="500px">
          <el-table-column align="center" label="发票-业务-场景">
            <template slot-scope="scope">
              <span v-if="scope.row.TaskSceneName">
                {{ scope.row.InvoiceTypeName }} / {{ scope.row.TaskTypeName }} /
                {{ scope.row.TaskSceneName }}</span>
              <span v-else> {{ scope.row.InvoiceTypeName }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="费率" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{
                scope.row.Rate ? scope.row.Rate + "%" : scope.row.Rate
              }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="技术服务费费率" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{
                scope.row.TecRate ? scope.row.TecRate + "%" : scope.row.TecRate
              }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <el-button type="primary" size="small" @click="toDetail(scope.row)"
                :disabled="!scope.row.RateID">查看历史记录</el-button>
              <el-button type="primary" size="small" @click="addSign(scope.row)"
                :disabled="fadadaStatus != 100">发起电签</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <!-- 查看费率历史记录 -->
    <el-dialog title="历史记录" :visible.sync="rateRecordDialog" width="40%" v-if="rateRecordDialog" class="rate-dialog">
      <div style="text-align: center; font-weight: bold; margin-bottom: 10px">
        发票-业务-场景：
        <span v-if="currentItem.TaskSceneName">
          {{ currentItem.InvoiceTypeName }} / {{ currentItem.TaskTypeName }} /
          {{ currentItem.TaskSceneName }}</span>
        <span v-else> {{ currentItem.InvoiceTypeName }}</span>
      </div>
      <el-table :data="rateDataRecord" :header-cell-style="{ background: '#f0f0f0', color: '#666' }" v-loading="loading">
        <el-table-column type="index" align="center" label="序号" width="50" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Rate" label="费率" show-overflow-tooltip>
          <template slot-scope="scope">{{
            scope.row.Rate ? scope.row.Rate + "%" : ""
          }}</template>
        </el-table-column>
        <el-table-column align="center" prop="TecRate" label="技术服务费费率" show-overflow-tooltip>
          <template slot-scope="scope">{{
            scope.row.TecRate ? scope.row.TecRate + "%" : ""
          }}</template>
        </el-table-column>
        <el-table-column align="center" prop="CteatedTime" label="变更时间" show-overflow-tooltip>
        </el-table-column>
      </el-table>
      <div style="margin-top: 10px">
        <el-pagination background class="pagination" @current-change="handleCurrentChangeRecord"
          :current-page.sync="paginationRecord.page" :page-size="paginationRecord.pagesize"
          layout="total, prev, pager, next, jumper" :total="paginationRecord.total"></el-pagination>
      </div>
    </el-dialog>
    <!-- 选择电签时间 -->
    <el-dialog :visible.sync="chooseTimeDialog" width="25%" append-to-body>
      <span slot="title">选择电签日期</span>
      <div style="display: flex;align-items:center;padding-bottom: 30px;">
        电签日期：
        <el-date-picker v-model="signDate" value-format="yyyy-MM-dd" type="date" placeholder="选择日期">
        </el-date-picker>
        <el-button type="primary" size="small" @click="toSubmitSign" style="margin-left: 15px;">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getCustomerBy,
  UpdateAgentID,
} from "@/api/platform/ownerPricing/index";
import { GetOwnerRate, SetOwnerRate, GetRateRecords } from "@/api/newRate";
import { EContractSignAdd, GetFadadaStatus } from '@/api/common/common'
import { _getUserId } from "@/utils/storage";
export default {
  data() {
    return {
      // 推荐人ID
      AgentName: "",
      AgentPhone: "", //推荐人id
      // 控制弹窗集合
      flag: {
        // 设置分成比例弹窗
        setDivide: false,
        // 设置推荐人弹窗
        setReferrer: false,
        // 查看费率
        rateMdel: false,
      },
      // TAB栏默认
      activeCmpt: "1",
      activeRateCmpt: "1",
      rateDataRecord: [],
      // 分页参数
      paginationRecord: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      // 筛选条件数据
      manageForm: {
        EnterpriseFullName: "",
        LegalPersonlPhone: "",
        TaskDatetime: ["", ""],
      },
      // 分页参数
      pagination: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      // 表格数据
      tableData: [],
      // 表格loading
      loading: true,
      // 发票费率
      invoiceRate: [],
      // 当前货主信息
      ownerInfo: "",
      // 费率数据
      rateData: [],
      currentItem: "",
      rateRecordDialog: false, // 历史记录弹窗
      selectInfo: {},
      signDate: new Date(),
      chooseTimeDialog: false,
      fadadaStatus: null
    };
  },
  methods: {
    searchChange() {
      this.search();
    },
    // 获取法大大用户状态
    getFadadaStatus() {
      GetFadadaStatus({ userId: this.ownerInfo.UserID }).then((res) => {
        this.fadadaStatus = res.data.Status
      })
    },
    // 查看费率
    examineRate(item) {
      this.activeRateCmpt = "1";
      this.ownerInfo = item;
      this.getFadadaStatus()
      GetOwnerRate({ userid: item.UserID }).then((res) => {
        if (res.data.length == 0) {
          this.$message.error("该买家暂未设置费率");
          return;
        } else {
          let list = [];
          res.data.forEach((item) => {
            item.Rate = item.Rate == 0 || item.Rate == null ? "" : item.Rate;
            item.TecRate =
              item.TecRate == 0 || item.TecRate == null ? "" : item.TecRate;
            list.push(item);
          });
          this.rateData = list;
        }
        this.flag.rateMdel = true;
      });
      // this.getRateChangeRecord();
    },
    // 发起电签
    addSign(item) {
      this.selectInfo = item
      this.$confirm("确定发起电签吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.chooseTimeDialog = true
        this.signDate = new Date()
      });
    },
    toSubmitSign() {
      const loading = this.$loading({
        lock: true,
        text: "请求中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      EContractSignAdd({
        taskSceneTypeID: this.selectInfo.TaskSceneTypeID,
        aUserID: this.ownerInfo.UserID,
        bUserID: parseInt(_getUserId()) / 5,
        signDate: this.signDate,
        pctType: 0,
        rate: this.selectInfo.Rate || '0.00',
        tecRate: this.selectInfo.TecRate || '0.00',
        defaultRate: this.selectInfo.DefaultRate,
      })
        .then(() => {
          this.$message.success("发起电签成功!");
        })
        .finally(() => {
          this.chooseTimeDialog = false
          loading.close();
        });
    },
    // 设置推荐人
    setReferrer() {
      let data = {
        AgentName: this.AgentPhone,
        CustomerID: this.ownerInfo.UserID,
      };
      UpdateAgentID({ Json: JSON.stringify(data) }).then((res) => {
        this.flag.setReferrer = false;
      });
    },
    // 显示推荐人弹窗
    goFacility(item) {
      this.ownerInfo = item;
      this.flag.setReferrer = true;
    },
    // 输入的费率
    raetInp(item, e) {
      if (Number(e) >= item.DefaultRate) {
        this.$confirm(
          `${item.InvoiceTypeName} / ${item.TaskTypeName} / ${item.TaskSceneName}的结算费率大于发票费率。如您坚持设置此数值，请与各相关方协商好。谢谢~`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        ).then(() => { });
      }
    },
    // 确定设置货主费率
    setOwnerDivide() {
      let ratelist = [];
      for (let i = 0; i < this.invoiceRate.length; i++) {
        let obj = this.invoiceRate[i];
        // 按需求去除该校验
        // if (this.$entrustSceneIds.includes(Number(obj.TaskSceneTypeID))) {
        //   if (Number(obj.Rate) + Number(obj.TecRate) <= 0) {
        //     this.$message.warning("买家结算费率和技术服务费不能同时为空！");
        //     return;
        //   }
        // }
        ratelist.push({
          taskSceneTypeID: obj.TaskSceneTypeID,
          rateID: obj.RateID,
          rate: obj.Rate,
          tecRate: obj.TecRate,
        });
      }
      let data = {
        userID: this.ownerInfo.UserID,
        optionID: this.ownerInfo.OptionID,
        agentRate: ratelist,
      };
      const loading = this.$loading({
        lock: true,
        text: "设置中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      SetOwnerRate(data)
        .then((res) => {
          this.getCustomerBy();
          this.flag.setDivide = false;
          this.$message.success("设置成功");
        })
        .finally(() => {
          loading.close();
        });
    },
    // 获取货主费率
    setDivide(item) {
      this.ownerInfo = item;
      GetOwnerRate({ userid: item.UserID }).then((res) => {
        let rateList = [];
        if (res.data && res.data.length) {
          res.data.forEach((item) => {
            item.Rate = item.Rate == 0 || item.Rate == null ? "" : item.Rate;
            item.TecRate =
              item.TecRate == 0 || item.TecRate == null ? "" : item.TecRate;
            rateList.push(item);
          });
        }
        this.invoiceRate = rateList;
        this.flag.setDivide = true;
      });
    },
    // 搜索
    search() {
      this.pagination.page = 1;
      this.getCustomerBy();
    },
    // 清空搜索
    resetForm() {
      this.manageForm = {
        EnterpriseFullName: "",
        LegalPersonlPhone: "",
        TaskDatetime: ["", ""],
      };
      this.pagination.page = 1;
      this.getCustomerBy();
    },
    // TAB栏点击事件
    cmptChange(e) {
      this.AgentChannel = e.name;
      this.pagination.page = 1;
      this.getCustomerBy();
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.pagination.page = e;
      this.getCustomerBy();
    },
    // 获取货主列表
    getCustomerBy() {
      this.loading = true;
      let data = {
        EnterpriseFullName: this.manageForm.EnterpriseFullName,
        LegalPersonlPhone: this.manageForm.LegalPersonlPhone,
        SubmitDatetimeStart: this.manageForm.TaskDatetime[0],
        SubmitDatetimeEnd: this.manageForm.TaskDatetime[1],
        pageIndex: this.pagination.page,
        pageSize: this.pagination.pagesize,
        AgentChannel: this.activeCmpt,
      };
      getCustomerBy({ Json: JSON.stringify(data) })
        .then((res) => {
          this.tableData = res.enterpriseBase;
          this.pagination.total = Number(res.totalRowCount);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    // 查看历史记录
    toDetail(item) {
      this.currentItem = item;
      this.getRateChangeRecord();
    },
    //分页控件页码change事件回调
    handleCurrentChangeRecord(e) {
      this.paginationRecord.page = e;
      this.getRateChangeRecord();
    },

    // 获取历史费率
    getRateChangeRecord() {
      this.loading = true;
      let data = {
        pageIndex: this.paginationRecord.page,
        PageSize: this.paginationRecord.pagesize,
        rateID: this.currentItem.RateID,
      };
      GetRateRecords(data)
        .then((res) => {
          this.rateDataRecord = res.data.DataList || [];
          this.paginationRecord.total = Number(res.data.TotalCount);
          this.rateRecordDialog = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    // 获取货主列表
    this.getCustomerBy();
  },
};
</script>

<style lang="scss" scoped>
.dialog_box {
  font-size: 14px;
  line-height: 26px;
  width: 100%;
  background: #f8f8f8;
  padding: 20px 1%;
  box-sizing: border-box;
  color: #666;
  border-radius: 5px;

  .dialogRole {
    width: 100%;
    text-align: center;
  }

  .dialogInfo {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 16px;
  }
}

.deep_dialog1 {
  ::v-deep .el-dialog {
    margin-top: 5vh !important;
  }
}

.deep_dialog {
  ::v-deep .el-dialog__header {
    border-bottom: 1px solid #eee;
  }

  ::v-deep .el-dialog__footer {
    border-top: 1px solid #eee;
    text-align: center;
  }

  ::v-deep .el-dialog__body {
    // padding: 0px 20px 20px;
  }
}
</style>
